<li ngbDropdown class="nav-item dropdown-notification mr-25" *ngIf="!isPartner">
  <a class="nav-link" *ngIf="isBManger" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i>
    <!-- <span *ngIf="notifications.messages?.length+notifications.systemMessages?.length>0" class="badge badge-pill badge-danger badge-up">
      {{ notifications.messages?.length + notifications.systemMessages?.length }}
    </span> -->
    <span *ngIf="(notifications_request_count *1 + notifications_abnomal_count*1 + notifications_timedout_count*1)>0" class="badge badge-pill badge-danger badge-up">
      {{ notifications_request_count + notifications_abnomal_count + notifications_timedout_count }}
    </span>
  </a>

  <a class="nav-link" *ngIf="isSystemM" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i>
    <!-- <span *ngIf="notifications.messages?.length+notifications.systemMessages?.length>0" class="badge badge-pill badge-danger badge-up">
      {{ notifications.messages?.length + notifications.systemMessages?.length }}
    </span> -->
    <span *ngIf="(notifications_abnomal_count*1 + support_requests.length*1 + pendingPost.length*1 )>0" class="badge badge-pill badge-danger badge-up">
      {{ notifications_abnomal_count + support_requests.length + pendingPost.length }}
    </span>
  </a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <!-- <div class="badge badge-pill badge-light-primary" *ngIf="isBManger"> -->
          <!-- {{ notifications.messages?.length + notifications.systemMessages?.length }} New -->
          <!-- {{ notifications_request_count + notifications_abnomal_count }} New
        </div> -->
        <!-- <div class="badge badge-pill badge-light-primary" *ngIf="isSystemM"> -->
          <!-- {{ notifications.messages?.length + notifications.systemMessages?.length }} New -->
          <!-- {{ notifications_abnomal_count + support_requests.length + pendingPost.length }} New
        </div> -->
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>

      <!-- <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications.messages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar">
              <img [src]="message.image" alt="avatar" width="32" height="32" />
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.heading"></p>
            <small class="notification-text">{{ message.text }}</small>
          </div>
        </div>
      </a>

      <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': systemMessage.icon === 'x',
                'bg-light-success': systemMessage.icon === 'check',
                'bg-light-warning': systemMessage.icon === 'alert-triangle'
              }"
            >
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
            <small class="notification-text">{{ systemMessage.text }}</small>
          </div>
        </div>
      </a> -->

      <!-- <a class="d-flex">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar bg-light-warning">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'package'"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading mb-25">
              Overtime Parcels
            </p>
            <small class="notification-text">
              <span id="machine-name">Machine Long Name Test</span>&nbsp;has <span id="overtime-parcel-number">7</span>&nbsp;overtime parcels. Please remind the residents to collect their parcels.
            </small>
          </div>
        </div>
      </a> -->

      <a class="d-flex" *ngFor="let item of notifications.parcelMessages" >
        <div class="media d-flex align-items-start" *ngIf="isBManger && notifications.parcelMessages.length>0" >
          <div class="media-left">
            <div class="avatar bg-light-danger">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'alert-circle'"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading mb-25">
              URGENT Unattended Delivery
            </p>
            <small class="notification-text">
              <span id="machine-name">You have&nbsp;<span id="overtime-parcel-number">{{ item.count }}</span>&nbsp;parcels that need immediate attention.</span>
            </small>
            <a class="d-flex" [routerLink]="['/settings/',item.buid]" [queryParams]="{panel: false}" routerLinkActive="router-link-active" >
              <div>{{ item.bname }} &nbsp;&nbsp;&nbsp;&nbsp;  </div>
              <div class="badge badge-pill badge-light-primary">{{ item.count }}</div>
            </a>
          </div>
        </div>
      </a>

      <a class="d-flex"  *ngFor="let item of notifications.requestMessages" >
        <div class="media d-flex align-items-start" *ngIf="isBManger && notifications.requestMessages.length>0" >
          <div class="media-left">
            <div class="avatar bg-light-warning">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'user-plus'"></i></div>
            </div>
          </div>
          <div class="media-body" >
            <p class="media-heading mb-25">
              Unit Join Request
            </p>
            <small class="notification-text">
              You have <span id="customer-support-number">{{ item.count }}</span>&nbsp;join requests waiting to be processed.
            </small>
            <a class="d-flex" [routerLink]="['/settings/',item.buid]" [queryParams]="{panel: false}" routerLinkActive="router-link-active" >
              <div>{{ item.bname }} &nbsp;&nbsp;&nbsp;&nbsp;  </div>
              <div class="badge badge-pill badge-light-primary">{{ item.count }}</div>
            </a>
          </div>
        </div>
      </a>
<!-- Timeout parcels list -->
      <a class="d-flex"  *ngFor="let item of notifications.timeOutList" >
        <div class="media d-flex align-items-start" *ngIf="isBManger && notifications.timeOutList.length>0" >
          <div class="media-left">
            <div class="avatar bg-light-primary">
              <div class="avatar-content"><i data-feather="package" class="avatar-icon"></i></div>
            </div>
          </div>
          <div class="media-body" >
            <p class="media-heading mb-25">
              Timeout Parcels
            </p>
            <small class="notification-text">
              You have <span id="timeout-parcel-number">{{ item.count }}</span>&nbsp;timeout parcels.
            </small>
            <a class="d-flex" (click)="setupBuid(item.uuid)" routerLinkActive="router-link-active">
              <div class="timeout-text">{{ item.addr }} &nbsp;&nbsp;&nbsp;&nbsp;  </div>
              <div class="badge badge-pill badge-light-primary">{{ item.count }}</div>
            </a>
          </div>
        </div>
      </a>

      <!-- <a class="d-flex"  >
        <div class="media d-flex align-items-start" *ngIf="isBManger && timedout.length>0" >
          <div class="media-left">
            <div class="avatar bg-light-warning">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'clock'"></i></div>
            </div>
          </div>
          <div class="media-body" *ngFor="let item of timedout">
            <p class="media-heading mb-25">
              Timedout Parcels
            </p>
            <small class="notification-text" >
              You have <span id="customer-support-number">{{ item.count }}</span>&nbsp;timedout parcels at {{item.addr}}
            </small>
            <a class="d-flex" [routerLink]="['/parcels/',item.uuid]" routerLinkActive="router-link-active" >
              <div>{{ item.bname }} &nbsp;&nbsp;&nbsp;&nbsp;  </div>
              <div class="badge badge-pill badge-light-primary">{{ item.count }}</div>
            </a>
          </div>
        </div>
      </a> -->

      <a class="d-flex" *ngIf="isSystemM && support_requests.length>0" [routerLink]="['/customer-service']" routerLinkActive="router-link-active" >
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar bg-light-warning">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'message-square'"></i></div>
            </div>
          </div>
          <div class="media-body" >
            <p class="media-heading mb-25">
              Customer Support Request
            </p>
            <small class="notification-text">
              You have <span id="customer-support-number" class="badge badge-pill badge-light-primary">{{ support_requests.length }}</span>&nbsp;support requests waiting to be processed.
            </small>
            <!-- <a class="d-flex" [routerLink]="['/settings/',item.buid]" [queryParams]="{panel: false}" routerLinkActive="router-link-active" >
              <div>{{ item.bname }} &nbsp;&nbsp;&nbsp;&nbsp;  </div>
              <div class="badge badge-pill badge-light-primary">{{ item.count }}</div>
            </a> -->
          </div>
        </div>
      </a>

      <a class="d-flex" *ngIf="isSystemM && unattended.length>0" [routerLink]="['/admin-view']" routerLinkActive="router-link-active" >
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar bg-light-danger">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'alert-circle'"></i></div>
            </div>
          </div>
          <div class="media-body" >
            <p class="media-heading mb-25">
              URGENT Unattended Delivery
            </p>
            <small class="notification-text">
              <span id="machine-name">You have&nbsp;<span id="overtime-parcel-number" class="badge badge-pill badge-light-primary">{{ unattended.length }}</span>&nbsp;parcels that need immediate attention.</span>
            </small>
          </div>
        </div>
      </a>
      <a class="d-flex" *ngIf="isSystemM && pendingPost.length>0" [routerLink]="['/community']" routerLinkActive="router-link-active" >
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar bg-light-success">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'clipboard'"></i></div>
            </div>
          </div>
          <div class="media-body" >
            <p class="media-heading mb-25">
              New Community Post
            </p>
            <small class="notification-text">
              <span id="machine-name">You have&nbsp;<span id="overtime-parcel-number" class="badge badge-pill badge-light-primary">{{ pendingPost.length }}</span>&nbsp;community posts waiting to be processed.</span>
            </small>
          </div>
        </div>
      </a>
      
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <!-- <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a> -->
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
