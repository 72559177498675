import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "communityText",
})
export class CommunityTextPipe implements PipeTransform {
  transform(text: string): any {
    let result = text.replace(/--/g, ' ').replace(/@@/g, '\'').replace(/%%/g, '\"').replace(/::/g, '\\');
        
    return result.replace(/&nbsp;/g,' ');
   
  }
}