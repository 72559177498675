import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentTimeFormat'
})
export class momentTimeFormat implements PipeTransform {


  transform(time: any, type: any) : any{
    if(time && time != ""){
        return moment(time).format(type)
    }
    return ""
    

  }

}