import { Pipe, PipeTransform } from '@angular/core';
import { time } from 'console';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(input_time: string, timezone?:string) : any{
    if(input_time.length<=3){
      return 'N/A';
    }
    var parcel_date = input_time.split(/[- :T]/);
    var end_time = new Date(Date.UTC(Number(parcel_date[0]),Number(parcel_date[1])-1, Number(parcel_date[2]), Number(parcel_date[3]), Number(parcel_date[4]), Number(parcel_date[5])));
    var returnCt = '';
    if(timezone){
      returnCt = this.formatParcelDateWithAddress(end_time, timezone);
    }else{
      returnCt = this.formatParcelDate(end_time);
    }

    return returnCt;
  }
  
  formatParcelDate(date: Date){
    var year = date.getFullYear().toString();
    var month = (date.getMonth()+1).toString();
    var day = date.getDate().toString();
    var hour = date.getHours().toString();
    var minutes = date.getMinutes().toString();
    var seconds = date.getSeconds().toString();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minutes.length < 2) 
        minutes = '0' + minutes;
    if (seconds.length < 2) 
        seconds = '0' + seconds;
    var parcelDate = [year, month, day].join('-');
    var parcelTime = [hour, minutes, seconds].join(":");
    return parcelDate+" "+parcelTime;
  }

  formatParcelDateWithAddress(date: Date, timezone:string){
    let time = date.toLocaleString('en-US', { hour12:false, timeZone: timezone });
    // console.log(time);
    //format the time to yyyy-mm-dd hh:mm:ss
    var parcel_date = time.split(/[- :T,/]/);
    var year = parcel_date[2];
    var month = parcel_date[0];
    var day = parcel_date[1];
    var hour = parcel_date[4];
    var minutes = parcel_date[5];
    var seconds = parcel_date[6];
    if (month.toString().length < 2) 
        month = '0' + month;
    if (day.toString().length < 2) 
        day = '0' + day;
    var parcelDate = [year, month, day].join('-');
    var parcelTime = [hour, minutes, seconds].join(":");
    return parcelDate+" "+parcelTime;


  }
  

}
