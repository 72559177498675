import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunityAPIService {

  recentNote = [] // track the most recent 3 update notes  
  selectedNote = {} // track the selected note 

  // Base url
  baseurl = environment.communityUrl;
  n_url = environment.notificationUrl;
  // Http Headers
  // public headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  public requestOptions_get: Object = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      responseType: 'text'
  }
  // public headers_post = new HttpHeaders().set('Content-Type','application/json');
  public requestOptions_post: Object = {
      headers: new HttpHeaders().set('Content-Type','application/json'),
      responseType: 'text'
    }
  constructor(private http: HttpClient) { }
  // POST create a new bulltin
  CreateNewBulltin(data): Observable<any> {
    
    return this.http.post(this.baseurl + '/bulletinlist', data, this.requestOptions_post);
     
  }
  // GET a single bulltin
  GetOneBulltin(id): Observable<any> {
    
    return this.http.post<string>(this.baseurl + '/bulletin/' + id, this.requestOptions_post)
    
  }

  // GET all bulltinList
  GetBulltinList(pars): Observable<any> {
    
    return this.http.post(this.baseurl + '/bulletinlistget', pars, this.requestOptions_post);
    
  }

  // GET my bulltinList by userId
  GetMyBulltinList(id): Observable<any> {
    return this.http.post<string>(this.baseurl + '/mybulletin/'+ id, this.requestOptions_post)
    
  }

  // POST create a new bulltin
  updateStatus(id,data): Observable<any> {

    return this.http.post<string>(this.baseurl + '/bulletinstatus/' + id, data, this.requestOptions_post);
      
  }

  // Delete a bulltin
  DeleteBulltin(id): Observable<any> {
   
    return this.http.post<string>(this.baseurl + '/bulletindelete/' + id, this.requestOptions_post);
  }

  // view add one
  ViewBulltin(id): Observable<any> {
    return this.http.post<string>(this.baseurl + '/bulletinviews/' + id, this.requestOptions_post);
  }

  // vote bulltin
  VoteBulltin(id,data): Observable<any> {
    return this.http.post<string>(this.baseurl + '/bulletinvote/' + id, data, this.requestOptions_post);
  }

  // update priority
  updatePriority(id,data): Observable<any> {

    return this.http.post<string>(this.baseurl + '/bulletinpriority/' + id, data, this.requestOptions_post);
      
  }

  // create a notification
  createNotification(data): Observable<any> {
    return this.http.post<string>(this.n_url + '/create', data, this.requestOptions_post);
  }
}
