import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lockerSetupFilter'
})
export class LockerSetupFilter implements PipeTransform {


  transform(data: any[], ...args: unknown[]) : any{
    var result = []
    if(data.length > 0){
        result = data.filter(item => item.status && item.count>0)
    }
    return result

  }

}