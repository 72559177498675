import { Pipe, PipeTransform } from '@angular/core';
import { AnyCnameRecord } from 'dns';

@Pipe({
  name: 'offlineFilter'
})
export class OfflineFilter implements PipeTransform {


  transform(data: any[], b_filter: any) : any{
    var result = [];
    
    if(b_filter=='all'){
        result = data;
    }else{
        result = data.filter(item => {
            let province = item.address.split('|')[2];
            return province == b_filter;
        })
    }
    
    return result;
    

  }

}