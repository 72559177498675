import { Role } from './role';

export class User {
  ret: string;
  level: string;
  token?: string;
  partner?: string;
  bPartner?: boolean;
  mtypes?: any[];
}
