import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'statistic'
})
export class StatisticBuildPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any[], search: any): any {
    // console.log(items, search);
    var result = [];
    // var arr = [...items.parcels_complete, ...items.parcels_pending];
    if(items && items.length > 0){
      result = items.filter(item => 
          item.buid == search.buid
      )

    } else {
      return 0
    }
    // console.log(result);
    return result.length
  }
}