import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerContact'
})
export class CustomerContactPipe implements PipeTransform {


  transform(data: any) : any{
 
    if(data.includes('#')) {
        return data.split("#")[0]
    }
    return data

  }

}