import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';


// Interface
interface notification {
  parcelMessages: any;
  requestMessages: any;
  timeOutList: any;
  // system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  styleUrls: ['./navbar-notification.component.scss'],
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification = {parcelMessages: [], requestMessages: [], timeOutList: []};
  public notifications_request_count: number = 0;
  public notifications_abnomal_count: number = 0;
  public notifications_timedout_count: number = 0;
  public support_requests: any = [];
  public isSystemM: any = false;
  public isBManger: any = false;
  public isPartner: any = false;
  public unattended: any = [];
  public timedout: any = [];
  public pendingPost: any = []
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService,
    private router: Router,
    private _authenticationService:AuthenticationService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._notificationsService.onApiDataChange.subscribe(res => {
    //   this.notifications = res;
    // });
    // console.log(this._authenticationService.isSysAdmin)
    // console.log(this._authenticationService.isBdAdmin)
    this._authenticationService.currentUser.subscribe(data=>{
      if(data){
        this.isSystemM = this._authenticationService.isSysAdmin
        this.isBManger = this._authenticationService.isBdAdmin
        this.isPartner = this._authenticationService.isPartner;
      }
    })
    

    // get request count and detail
    this._notificationsService.onRequestList.subscribe(res => {
      this.notifications.requestMessages = res.filter(item => item.count!==0);
      // remove duplicate
      this.notifications.requestMessages = this.notifications.requestMessages.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.buid === value.buid
        ))
      )
      this.notifications_request_count = 0;
      if(this.notifications.requestMessages.length > 0){
       
        for(let item of this.notifications.requestMessages) {
          this.notifications_request_count += item.count
        }
        // console.log(this.notifications_request_count)
      }
     
    });

    // get abnormal parcels and count
    this._notificationsService.onAbnormalList.subscribe(data => {
      // remove duplicate
      var d = data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.buid === value.buid
        ))
      )
      this.notifications.parcelMessages = d.filter(item => item.count!==0);
      this.notifications_abnomal_count = 0
      if(this.notifications.parcelMessages.length > 0){
        for(let item of this.notifications.parcelMessages) {
          this.notifications_abnomal_count += item.count
        }
       
        // console.log(this.notifications_abnomal_count)
      } 
    })

    //get number of support requests
    this._notificationsService.supportRequests.subscribe(data => {
      // console.log(data)
      this.support_requests = data;
    })

    // get unattended delivery
    this._notificationsService.unattendedDelivery.subscribe(data => {
      this.unattended = data;
    })

    // get pending post number
    this._notificationsService.onPendingPost.subscribe(data => {
      // console.log(data)
      this.pendingPost = data;
    })
    this._notificationsService.onTimedoutParcelList.subscribe(data=>{
      this.notifications.timeOutList = data;
      this.notifications_timedout_count = 0;
      //remove duplicate
      this.notifications.timeOutList = this.notifications.timeOutList.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.uuid === value.uuid
        ))
      )
      if(this.notifications.timeOutList.length > 0){
       
        for(let item of this.notifications.timeOutList) {
          this.notifications_timedout_count += item.count
        }
      }
    })



    // get pending post number
    this._notificationsService.onTimedoutParcelList.subscribe(data=>{
      // console.log(data)
      this.pendingPost = data;
    })


  }

  setupBuid(buid){
    var temp=JSON.stringify({buid:buid, c:"", p:"", id:"", overtime:1});
    this.router.navigateByUrl('/parcels/'+temp);
  }

  // redirect(item) {
  //   var temp=JSON.stringify({buid:item.buid, c:"", p:"", id:""});
  //   this.router.navigateByUrl('/parcels/'+temp);
  // }
}
