import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'compartmentFilter'
})
export class CompartmentFilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {any[]} key
   *
   * @returns {any}
   */
  transform(items: any[], searchText: string): any[] {
    // console.log(searchText);
    if (!items) return [];
    if (!searchText) return items;
    if(searchText.toLowerCase()=="all" ) return items;
    if(searchText.toLowerCase()=="undefined"){
        return items.filter(it => {
            return it.size.includes("-1");
        })
    }else if(searchText.toLowerCase()=="defined"){
        return items.filter(it => {
            return !it.size.includes("-1");
        })
    }
    return items.filter(it => {
      return it.size.includes(searchText);
    });
  }
}
