import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any[], searchText: string): any[] {
    // console.log(searchText);
    if (!items) return [];
    if (!searchText) return items;
    if(searchText.toLowerCase()=="all" ) return items;
    

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      return it.category.toLowerCase().includes(searchText);
    });
  }
}
