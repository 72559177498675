import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html'
})
export class NavbarSearchComponent implements OnInit {
  // Public
  public searchText = '';
  public openSearchRef = false;
  public activeIndex = 0;
  public apiData;
  public pages = [];
  public files = [];
  public contacts = [];
  public pageSearchLimit;
  public isNumber=false;
  public isBdAdmin=false;
  public isSysAdmin = false;

  // Decorators
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;

  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    if (event.target.className === 'content-overlay') {
      this.removeOverlay();
      this.openSearchRef = false;
      this.searchText = '';
    }
  }

  /**
   *
   * @param document
   * @param router
   * @param _searchService
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _elementRef: ElementRef,
    private router: Router,
    public _searchService: SearchService,
    private _authService:AuthenticationService
  ) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      this.removeOverlay();
      this.openSearchRef = !this.openSearchRef;
      this.router.navigateByUrl('/parcels/'+this.searchText);
    //   var regex=/^[a-zA-Z]+$/;
    //   if (!event.target.value.match(regex))
    //   {
    //     this.removeOverlay();
    //     this.openSearchRef = !this.openSearchRef;
    //     this.router.navigate(['/parcels', "", "", this.searchText]);
    //   }else{
    //     this.removeOverlay();
    //     this.openSearchRef = !this.openSearchRef;
    //     this.router.navigate(['/parcels', "", this.searchText, ""]);
    // }
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this._searchService.onIsBookmarkOpenChange.next(false);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

    if (this.openSearchRef === false) {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
      this.searchText = '';
    }
  }

  /**
   * Search Update
   *
   * @param event
   */
  searchUpdate(event) {
    var regex=/^[a-zA-Z]+$/;
    if (!event.target.value.match(regex))
    {
        this.isNumber=true;
    }else{
      this.isNumber=false;
    }
    const val = event.target.value.toLowerCase();
    if (val !== '') {
      this.document.querySelector('.app-content').classList.add('show-overlay');
    } else {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      this.removeOverlay();
      this.openSearchRef = !this.openSearchRef;
      var temp;
      if(this.isNumber){
        temp=JSON.stringify({buid:"", c:"", p:this.searchText, id:""});
      }else{
        temp=JSON.stringify({buid:"", c:this.searchText, p:"", id:""});
      }
      this.router.navigateByUrl('/parcels/'+temp);
      this.searchText='';
    //   var regex=/^[a-zA-Z]+$/;
    //   if (!event.target.value.match(regex))
    //   {
    //     this.removeOverlay();
    //     this.openSearchRef = !this.openSearchRef;
    //     this.router.navigate(['/parcels', "", "", this.searchText]);
    //   }else{
    //     this.removeOverlay();
    //     this.openSearchRef = !this.openSearchRef;
    //     this.router.navigate(['/parcels', "", this.searchText, ""]);
    // }
    }
    // this.autoSuggestion(event);
  }

  searchCarrier(){
    var temp=JSON.stringify({buid:"", c:this.searchText, p:"", id:""});
    this.router.navigateByUrl('/parcels/'+temp);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.searchText='';
  }

  searchPhone(){
    var temp=JSON.stringify({buid:"", c:"", p:this.searchText, id:""});
    this.router.navigateByUrl('/parcels/'+temp);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.searchText='';
  }

  searchEmail(){
    var temp=JSON.stringify({buid:"", c:"", p:"999999999", id:"", email:this.searchText});
    this.router.navigateByUrl('/parcels/'+temp);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.searchText='';
  }

  searchID(){
    var temp=JSON.stringify({buid:"", c:"", p:"", id:this.searchText});
    this.router.navigateByUrl('/parcels/'+temp);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.searchText='';
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    if(this._authService.currentUser){
      this.isBdAdmin = this._authService.isBdAdmin;
      this.isSysAdmin = this._authService.isSysAdmin;
    }else{

      this.isBdAdmin=true;
    }
    
    // this._searchService.onApiDataChange.subscribe(res => {
    //   this.apiData = res;
    //   this.pages = this.apiData[0].data;
    //   this.pageSearchLimit = this.apiData[0].searchLimit;
    //   this.files = this.apiData[1].data;
    //   this.contacts = this.apiData[2].data;
    // });
  }
}
