export const LogModel=
{
    '[machine-app-version-checked]': 'Machine system version check',
    '[machine-application-start-notify]': 'Machine application initiation successful',
    '[machine-restart-notify]': 'Machine reboot',
    '[machine-restart-self-application-notify]': 'Machine application reboot',
    '[machine-abnormal-parcel-order-complate]' : 'Abnormal parcel completed',

    // parcel
    'op-system-renew-open-code': {operation: 'Pickup code for parcel {} resent successfully', cat:'parcel'},
    'op-parcel-notify-number-update-ok': {operation: 'Contact number for parcel {} changed to {}', cat:'parcel'},
    'op-parcel-notify-number-update-fail':{operation: 'Contact number for parcel {} update failed', cat:'parcel'},
    'op-update-parcel-order-to-complate-ok': {operation: 'Parcel {} completed manually', cat:'parcel'},
    'op-machine-remote-open-cube': {operation: 'Parcel {} compartment door unlocked remotely', cat:'parcel'},
    'op-machine-remote-open-cube-with-parcel': {operation: 'Parcel {} compartment door unlocked remotely', cat:'parcel'},
    'op-manager-move-package-ok': {operation: 'Parcel list updated', cat:'parcel'},  
    'op-manager-move-package-fail': {operation: 'Parcel list update failed', cat:'parcel'}, 
    'op-system-send-open-code-by-email': {operation: 'Pickup code for parcel {} sent through email', cat:'parcel'},

    // login
    'op-manager-signin-ok': {operation: 'User {} login successful', cat:'login'},
    'op-manager-signin-fail':{operation: 'User {} login failed', cat:'login'},
    
    // account
    'op-manager-signup':{operation: 'New account created. User Name: {} Level: {}' , cat:'account'},
    'op-manager-signup-ex':{operation: 'New account created. User Name: {} Level: {}' , cat:'account'},
    'op-manager-password-reset-ok': {operation: 'Account {} password reset successful', cat:'account'},
    'op-manager-change-pwd-ok': {operation: 'Password update successful', cat:'account'},
    'op-manager-change-pwd-fail': {operation: 'Password update failed', cat:'account'},
    'op-manager-change-lock-ok': {operation: 'Account {} status update successful', cat:'account'}, 
    'op-manager-change-lock-fail': {operation: 'Account {} status update failed', cat:'account'},
    'op-manager-change-level-ok': {operation: 'Account {} level update successful', cat:'account'},
    'op-manager-change-level-fail': {operation: 'Account {} level update failed', cat:'account'},
    'op-manager-delete-ok': {operation: 'Account {} deleted', cat:'account'},
    'op-update-manager-avatar-info-ok': {operation: 'Account {} avatar update successful', cat:'account'},
    
    // unit
    'op-manager-new-building-user-ok': {operation: 'Phone number {} added', cat:'unit'},
    'op-manager-new-building-user-fail':{operation: 'Phone number {} failed to add', cat:'unit'},
    'op-manager-new-building-user-email-ok': {operation: 'Email {} added', cat:'unit'},
    'op-manager-new-building-user-email-fail': {operation: 'Email {} failed to add', cat:'unit'},
    'op-manager-new-building-user-already-has': {operation: 'Contact {} already exists in the list, action failed', cat:'unit'},
    'op-manager-del-building-user-ok':{operation: 'Contact {} removed', cat:'unit'},
    'op-manager-del-building-user-fail': {operation: 'Contact {} remove failed', cat:'unit'},
    'machine-abnormal-parcel-order-complate': {operation: 'Abnormal parcel {} completed ', cat:'unit'},
    'op-manager-new-building-user-email-already-has': {operation: 'Email {} already exists in the list, action failed', cat:'unit'},
    'op-update-building-room-address-ok': {operation: 'Single unit settings updated. rid:{} addr:{}', cat:'unit'},  
    // 关闭单个unit投递功能
    // operation not found[op-update-building-room-address-ok] op:81b80ea1-6283-11ec-91db-021f14e3de3e rid:6744 addr:condo1[DISABLE]
    // 恢复单个unit投递功能
    // operation not found[op-update-building-room-address-ok] op:81b80ea1-6283-11ec-91db-021f14e3de3e rid:6744 addr:condo1

    // // locker setting
    'op-machine-avatar-update-ok': {operation: 'Locker {} avatar update successful', cat:'locker-setting'},
    'op-manager-update-notice-ok':{operation: 'Building notice updated. Notice Content: {}', cat:'locker-setting'},
    'op-manager-new-notice-ok': {operation: 'New building notice initiated.', cat:'locker-setting'},
    'op-manager-update-machine-parcel-timeout-info-ok': {operation: 'Locker {} storage time limit update successful', cat:'locker-setting'},
    'op-manager-update-machine-parcel-timeout-info-fail': {operation: 'Locker {} storage time limit update failed', cat:'locker-setting'},
    'op-machine-scren-code-update': {operation: 'Carrier code update: {}', cat:'locker-setting'},
    'op-machine-screen-code-update-ok': {operation: 'Delivery passcode updated', cat:'locker-setting'},
    'op-manager-update-machine-bmb-info-ok': {operation: 'Building {} main contact update successful', cat:'locker-setting'},
    'op-add-new-language-type-ok': {operation: 'New language {} added', cat:'locker-setting'},
    'op-add-new-language-item-ok': {operation: ' New language entry {} added', cat:'locker-setting'},
    'op-update-language-item-ok': {operation: 'Language entry {} updated', cat:'locker-setting'},

    // machine
    'op-new-machine-ok': {operation: 'New locker {} created', cat:'machine'},
    'op-manager-new-bind-machine-ok': {operation: 'Locker {} binding successful ', cat:'machine'},
    'op-manager-new-bind-machine-fail': {operation: 'Locker {} binding failed ', cat:'machine'},
    'op-manager-delete-bind-machine-ok': {operation: 'Locker {} unbined with building', cat:'machine'},
    'op-manager-new-bind-building-ok': {operation: 'Locker {} binded to floor successfully', cat:'machine'},
    'op-manager-new-bind-building-fail':{operation: 'Locker {} faild bind to floor successfully', cat:'machine'},
    'op-manager-delete-bind-building-ok': {operation: 'Locker {} unbined with floor', cat:'machine'},
    'op-manager-delete-bind-building-fail': {operation: 'Locker {} failed to unbind with floor', cat:'machine'},
    'op-machine-remote-cmd': {operation: 'Locker {} {} ', cat:'machine'},
    // 'op-machine-remote-cmd': {operation: 'Locker {} reboot ', cat:'machine'},
    // 设备 [ b8ea35fc-d647-4b4f-89e8-b8bd8c175fb3 *设备重启* ] 远程指令已发送
    // 'op-machine-remote-cmd': {operation: 'Locker {} system settings on', cat:'machine'},
    //设备 [ b8ea35fc-d647-4b4f-89e8-b8bd8c175fb3 *打开设备系统设置* ] 远程指令已发送
    // 'op-machine-remote-cmd': {operation: 'Locker {} paused/resumed', cat:'machine'},	 这个能不能进一步判断出是pause还是resume?
    //设备 [ b8ea35fc-d647-4b4f-89e8-b8bd8c175fb3 *设备 暂停/恢复 使用* ] 远程指令已发送
    // 'op-machine-remote-cmd': {operation: 'Locker {} database reloaded ', cat:'machine'},
    // 设备 [ b8ea35fc-d647-4b4f-89e8-b8bd8c175fb3 *Reload Database* ] 远程指令已发送
    'op-machine-base-info-update': {operation: 'Locker {} root information update command sent', cat:'machine'},
    'op-manager-update-machine-base-info-ok': {operation: 'Locker {} root information updated successfully', cat:'machine'},
    'op-manager-update-machine-base-info-fail':{operation: 'Locker {} root information update failed', cat:'machine'},
    'op-new-machine-model-fail-had': {operation: 'Locker {} device model import failed, model already exists', cat:'machine'},
    'op-alias-cube-ok': {operation: 'Compartment alias update successful', cat:'machine'},
    'op-alias-cube-fail': {operation: 'Compartment alias update fail', cat:'machine'},
    'op-remodel-cube-locked-ok': {operation: '{} disabled compartment {}', cat:'machine'},
    'op-machine-run-mode-info-update': {operation: 'Locker {} unit management mode update successful', cat:'machine'}, 
    'op-manager-update-machine-run-mode-info-ok': {operation: 'Locker {} unit management mode update requested', cat:'machine'},
    'op-manager-update-machine-run-mode-info-fail': {operation: 'Locker {} unit management mode update request failed', cat:'machine'},
    'op-resize-cube-ok': {operation: 'Compartment size update successful', cat:'machine'},
    'op-resize-cube-fail': {operation: 'Compartment size update fail', cat:'machine'},

    'op-update-machine-model-info-ok': {operation: 'Machine model description update successful', cat:'machine'},
    'op-new-machine-model-ok': {operation: 'New machine model created', cat:'machine'},
    'op-remodel-cube-ok': {operation: 'Compartment model update successful', cat:'machine'},
    'op-manager-update-machine-storage-info-ok': {operation: 'Number of compartment update successful', cat:'machine'},
    'op-system-sms-stencil-update': {operation: 'SMS template updated. Tag: {} Content:{}', cat:'sms'},

    
    // advertisement
    'op-manager-del-ad-service-ok': {operation: 'Advertisement deleted {}', cat:'ad'},
    'op-manager-update-ad-service-ok': {operation: 'Advertisement updated {sid, status}', cat:'ad'},
    'op-manager-new-ad-service-ok': {operation: 'Advertisement created', cat:'ad'},
    'op-manager-updt-ad-ok': {operation: 'Advertisement {} update successful', cat:'ad'},
    'op-manager-new-ad-ok': {operation: 'Advertisement {} created', cat:'ad'},
    'op-manager-del-ad-ok': {operation: 'Advertisement {} deleted', cat:'ad'},
    'op-manager-new-ad-ex-ok': {operation: 'Advertisement file {} created', cat:'ad'},
    'op-manager-updt-ex-ad-ok': {operation: 'Advertisement id{} updated', cat:'ad'},
 
    // member
    'op-update-manager-company-info-ok': {operation: 'Organization id{} information updated', cat:'member'},
    'op-create-manager-company-info-ok': {operation: 'Organization id{} created', cat:'member'},
    'op-update-manager-company-avatar-info-ok': {operation: 'Organization id{} avatar updated', cat:'member'},
    'op-update-manager-ex-info-ok': {operation: '{} updated member id{} info', cat:'member'}, 	
}



export const CategoryList=['Parcel', 'Login', 'Account', 'Locker-setting', 'Machine', 'Unit', 'Member', 'Ad'];