import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { DataManageService } from './data-manage.service';
import { Buildings } from 'app/data/buildings';
import { BuildingsService } from './buildings.service';

@Injectable({
  providedIn: 'root'
})
export class TimestampService {
  lastRequest: any;

  constructor(private _authenticationService:AuthenticationService,
    private data_manager:DataManageService,
    private _buildings: BuildingsService,
    ) { }

  getTime(){
    var temp_time:any = JSON.parse(localStorage.getItem("lastRequest"));
    if(temp_time){
      var cur_time = new Date();
      var diff_time = cur_time.getTime()-Number(temp_time.time);
      var min = Math.round(diff_time%86400000%3600000/60000);
      // console.log(min);
      if(min<5){
        var return_time = new Date(temp_time.time);
        return return_time;
      }else{
        temp_time.time = new Date().getTime();
        localStorage.setItem('lastRequest', JSON.stringify(temp_time));
        this.renewBuildingsData();
        // this.notificationS.getCustomerRequest();
      }
      // console.log(min);
      var return_time = new Date(temp_time.time);
      return return_time;
    }else{
      temp_time = {time: new Date().getTime()};
      var return_time = new Date(temp_time.time);
      // console.log(temp_time);
      localStorage.setItem('lastRequest', JSON.stringify(temp_time));

      return return_time;
    }
  }


  renewTimestamp(){
    var temp_time = {time: new Date().getTime()};
    localStorage.setItem('lastRequest', JSON.stringify(temp_time));
  }


  renewBuildingsData() {
    if (this._authenticationService.currentUserValue.level == '1' || this._authenticationService.currentUserValue.level == '11' || this._authenticationService.currentUserValue.level == '12') {
      var n = 50004;
    } else {
      var n = 50005;
    }
    var url_parameters = { 't': this._authenticationService.currentUserValue.token, 'a': this._authenticationService.currentUserValue.ret };
    var encoded_url = this.data_manager.encryptMsg(url_parameters)
    var temp_input = { act: n, m: 1, par: encoded_url }
    this.data_manager.http_request(temp_input)
      .subscribe(
        {
          next: ret => {
            var decrypted = this.data_manager.decryptMsg(ret);
            var buildings: Buildings[] = JSON.parse(decrypted);
            buildings.sort((a,b)=>(a.bname>b.bname)?1:-1);
            localStorage.setItem('currentBuilding', JSON.stringify(buildings));
            this._buildings.currentBuildingSubject.next(buildings);
          },
          error: err => {
            console.log(err)
          }
        }

      );
  }
}
