import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lockerName'
})
export class LockerName implements PipeTransform {


  transform(data: string, ind:number) : any{

    let temp = data.split("#");
    return temp[ind];

  }

}