import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(num: string) : any{
      var n = Number(num);
    if (n > 999 && n < 1000000) {
        return (n / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
      } else if (n >= 1000000) {
        return (n / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
      } else if (n <= 999) {
        return n; // if value < 1000, nothing to do
      }
  }

}