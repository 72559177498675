import { Pipe, PipeTransform } from '@angular/core';
import { group } from 'console';

@Pipe({
  name: 'adGroupNamePipe',
  pure: false
})
export class AdGroupNamePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(groupList: any[], name:string) : any{
    //   console.log(type + isStar)
    if(name == ""){
        return groupList;
    }
    return groupList.filter((item)=> item.gname.toLowerCase().includes(name.toLowerCase()));

  }

}