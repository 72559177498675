import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "order_filter",
})
export class OrderFilterPipe implements PipeTransform {
  transform(items: any[],keyword: any): any {
    
    // filter by location
    // console.log(locations)
    if(!keyword){
        return items;
    }

    else{
        var result = items.filter((item) => {
            if (item.mob.toString().includes(keyword) || item.bc.toString().includes(keyword) || item.email.toString().includes(keyword)) {
            return true;
            }
            return false;
        });
        return result;
    }

    // return items;
  }
}