import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'baddrFormat'
})
export class BaddrPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(addr: string) : any{
    if(addr.includes('|')){
      var addr_new = addr.split('|');
      var result = addr_new[0]+', '+addr_new[1]
      return result;

    } else {
      return addr
    }
  }

}