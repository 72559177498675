// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  version: '1.2.4',
  apiUrl: 'http://localhost:4000',
  key: "7B3y2TiO2bF92fV7",
  iv:"8NONwyJtHesysWpM",
  key2: "3D6t5JiO2sW72pM8",
  iv2:"7MOJytHeJsetsMyH",
  // DEV ENVIRONMENT:
  be_url:"https://dev.bluemythglobal.com/bluebox/bluebox", upload_image_url: "https://devrc.bluemythglobal.com/blueimage/blueimage", rc_url:"https://devrc.bluemythglobal.com/blueimage/", 
  // PROD ENVIRONEMNT:
  // rc_url:"https://rc.bluemythglobal.com/blueimage/", be_url:"https://box.bluemythglobal.com/bluebox/bluebox", upload_image_url: "https://rc.bluemythglobal.com/blueimage/blueimage",
  communityUrl:"http://3.99.143.124/community",
  commentsUrl: "http://3.99.143.124/comments",
  communitySysUrl: "http://3.99.143.124/system",
  notificationUrl: "http://3.99.143.124/notification",
  releaseNoteUrl: "http://3.99.143.124/post",
  jumpUrl: "https://dev.bluemythglobal.com/bluebox/JumpStatistics/boxjump?",
  firebase: {
    apiKey: "AIzaSyDtNxmWyXiwWPKjR8EXlLSdpsWhD7hBmTY",
    authDomain: "bluebox-management-website.firebaseapp.com",
    projectId: "bluebox-management-website",
    storageBucket: "bluebox-management-website.appspot.com",
    messagingSenderId: "97576884888",
    appId: "1:97576884888:web:340a07ec522742b0924eed",
    measurementId: "G-E3JMH872B0"
  },
  shopUrl: 'https://devbluemart.bluemyth.ca',
  // communityUrl:"https://community.bluemyth.ca/community"
  // production: true,
  // hmr: false,
  // version: '1.0.2',
  // apiUrl: 'http://localhost:4000',
  // key: "7B3y2TiO2bF92fV7",
  // iv:"8NONwyJtHesysWpM",
  // key2: "3D6t5JiO2sW72pM8",
  // iv2:"7MOJytHeJsetsMyH",
  // rc_url:"https://rc.bluemythglobal.com/blueimage/",
  // be_url:"https://box.bluemythglobal.com/bluebox/bluebox",
  // upload_image_url: "https://rc.bluemythglobal.com/blueimage/blueimage",
  // communityUrl:"https://community.bluemyth.ca/community",
  // commentsUrl: "https://community.bluemyth.ca/comments",
  // notificationUrl: "https://community.bluemyth.ca/notification",
  // jumpUrl: "https://box.bluemythglobal.com/bluebox/JumpStatistics/boxjump?",
  // communitySysUrl: "https://community.bluemyth.ca/system"
};
//be_url:"https://dev.bluemythglobal.com/bluebox/bluebox"
//be_url:"https://box.bluemythglobal.com/bluebox/bluebox"
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
