import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { PhoneFormatPipe } from '@core/pipes/phone-format.pipe';
import { TimeFormatPipe } from '@core/pipes/time-format.pipe';
import { NumberFormatPipe } from '@core/pipes/number.pipe';
import { AddressPipe } from './address.pipe';
import { LogFormatePipe } from '@core/pipes/log-formate.pipe';
import { CompartmentFilterPipe } from './compartment.pipe';
import { CarrierImgPipe } from './carrier-img.pipe';
import { LocationFilterPipe } from './location-filter.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { PnotesPipe } from './pnotes.pipe';
import { BaddrPipe } from './baddr.pipe';
import { OddEvenPipe } from './odd-even.pipe';
import { LockerSetupFilter } from './locker-setup.pipe';
import { StatisticBuildPipe } from './statistic-buid.pipe';
import { CommunitySearchPipe } from './community-search.pipe';
import { CommunityLocationPipe } from './community-location.pipe';
import { CommunityCategoryPipe } from './community-categroy.pipe';
import { CommunityTextPipe } from './community-text.pipe';
import { ADStatistic } from './ad-statistic.pipe';
import { ADAgentFilter } from './ad-agent-filter.pipe';
import { ADTimeFormat } from './ad-time-format.pipe';
import { AdGroupPipe } from './ad-group.pipe';
import { AdGroupNamePipe } from './ad-group-name.pipe';
import { CustomerContactPipe } from './customer-contact.pipe';
import { ParcelPipe } from './parcels.pipe';
import { RedirectFilterPipe } from './redirect-filter.pipe';
import { momentTimeFormat } from './moment-time-format.pipe';
import { OfflineFilter } from './admin-view-offline.pipe';
import { OrderFilterPipe } from './order-filter.pipe';
import { AdTypeNumPipe } from './ad-type-num.pipe';
import { LockerName } from './locker-name.pipe';


@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, 
    SafePipe, PhoneFormatPipe, TimeFormatPipe, NumberFormatPipe, 
    AddressPipe, LogFormatePipe, CompartmentFilterPipe, 
    CarrierImgPipe, LocationFilterPipe, SearchFilterPipe,
    PnotesPipe, BaddrPipe, OddEvenPipe, LockerSetupFilter, 
    StatisticBuildPipe, CommunitySearchPipe, CommunityLocationPipe,
    CommunityCategoryPipe, CommunityTextPipe, ADStatistic, ADAgentFilter, ADTimeFormat,
    AdGroupPipe, AdGroupNamePipe, CustomerContactPipe, ParcelPipe, RedirectFilterPipe,
    momentTimeFormat, OfflineFilter, OrderFilterPipe, AdTypeNumPipe, LockerName],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, 
    SafePipe, PhoneFormatPipe, TimeFormatPipe, NumberFormatPipe, 
    AddressPipe, LogFormatePipe, CompartmentFilterPipe, 
    CarrierImgPipe, LocationFilterPipe, SearchFilterPipe,
    PnotesPipe, BaddrPipe, OddEvenPipe, LockerSetupFilter, 
    StatisticBuildPipe, CommunitySearchPipe, CommunityLocationPipe,
    CommunityCategoryPipe, CommunityTextPipe, ADStatistic, ADAgentFilter, ADTimeFormat,
    AdGroupPipe, AdGroupNamePipe, CustomerContactPipe, ParcelPipe, RedirectFilterPipe,
    momentTimeFormat, OfflineFilter, OrderFilterPipe, AdTypeNumPipe, LockerName]
})
export class CorePipesModule {}
