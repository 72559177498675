import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adStatistic'
})
export class ADStatistic implements PipeTransform {


  transform(data: any[],  search: any, b_filter: any[]) : any{
    var result = [];

    if (search == '') result = data;
    
    if(data.length > 0){
      result = data.filter(item => {
        return (item.name.toUpperCase()).includes(search.toUpperCase())
      })
    }
    if(b_filter.length > 0 && result.length >0){
      result = result.filter(item => b_filter.includes(item.name))
    }
    
    return result;
    

  }

}