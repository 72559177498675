import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { BehaviorSubject } from 'rxjs';
import { DataManageService } from './data-manage.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  target$ = new BehaviorSubject([]);
  urls = this.target$.asObservable();
  constructor(private _authService: AuthenticationService,
    private dataManager: DataManageService) {

        //this.getUrl();
      
    
  }

  getUrl(){
    var pram = {'t':this._authService.currentUserValue.token};
    var encoded_url=this.dataManager.encryptMsg(pram);
    var temp_input = {act: 50038, m:1, par:encoded_url};
    this.dataManager.http_request(temp_input).subscribe({
      next: ret=>{
        // console.log(this.dataManager.decryptMsg(ret));
        this.target$.next(JSON.parse(this.dataManager.decryptMsg(ret)));
      },
      error: err=>{
        console.log(err);
      }
    });
  }

}
