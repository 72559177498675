import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationFilter'
})
export class LocationFilterPipe implements PipeTransform {


  transform(data: any[], filterType: any, location: any, level: any) : any{
    // console.log("data: ", data);
    // console.log("location: ", location);
    // console.log("Level: ", level);
 
    if(!location || Object.keys(location).length === 0){
        return data;
    }
    var result = data.filter(ele => {
        if (level == '1'){
          var province = ele.baddr.split('|')[2].split(' ').join('').toUpperCase();
          var city = ele.baddr.split('|')[1].split(' ').join('');
        }else if (level == '10'){
          var province = ele.address.split('|')[2].split(' ').join('').toUpperCase();
          var city = ele.address.split('|')[1].split(' ').join('');
        }
        

        if (Object.keys(location).includes(province)) {
            if(Object.keys(location[province]).includes(city)){
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    })
    return result

  }

}