<div class="content-wrapper">
    <div class="content-header row">
        <!-- BEGIN: Page Header-->
        <div class="content-header-left col-md-9 col-sm-8 col-6 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-start mb-0">Subscription</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a>
                            </li>
                            <li class="breadcrumb-item active"><a routerLink="/parcels">Subscription</a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-header-right text-md-right col-md-3 col-sm-4 col-6 d-md-block"></div>
        <!-- END: Page Header-->
    </div> 
    <div class="content-body">
        <div class="subscription-tabs">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link ps-0 active" id="list-view" data-bs-toggle="tab" aria-controls="list-view" role="tab" aria-selected="true">All Buildings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link ps-0" id="unit-management-tab" data-bs-toggle="tab" aria-controls="unit-management" role="tab" aria-selected="false">Dashboard</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active"  id="list-view" aria-labelledby="list-view" role="tabpanel">
                    <div class="row">
                        <div class="col-12">
                            <div class="card top-action-bar">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="row mx-0">
                                            <div class="col-11 ps-0">
                                            <div class="input-group input-group-merge">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon-search2"><i data-feather="search"></i></span>
                                                </div>
                                                <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Search..."
                                                aria-label="Search..."
                                                aria-describedby="basic-addon-search2"
                                                />
                                            </div> 
                                            </div>
                                            <div class="col-1 pe-0">
                                            <button id="list-view-filter"  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBoth" aria-controls="offcanvasBoth" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary" ngbTooltip="Machine Filters" placement="top" ng-reflect-ngb-tooltip="Machine Filters" ng-reflect-placement="top" rippleEffect>
                                                <span [data-feather]="'filter'"></span>
                                            </button>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="tab-pane" id="unit-management" aria-labelledby="unit-management" role="tabpanel">
                    <div class="row match-height">
                        <div class="col-12">
                            Dashbaord
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
