import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parcelFilter'
})
export class ParcelPipe implements PipeTransform {

  /*
  * @param {any[]} items
  * @param {string} searchText
  * @param {string} key
  *
  * @returns {any}
  */
 transform(items: any[], searchText: string): any[] {
   // console.log(items);
   // type = 'even'
   if(!searchText){
      return items;
   }
   var results = [];
   results = items.filter((item, index) => {
      if (item.id.includes(searchText)) {
        return item;
      }else if(item.pkg.s.includes(searchText)){
        return item;
      }
    });
   
   return results;
 }

}
