import { Pipe, PipeTransform } from '@angular/core';
import { LogModel } from 'app/data/log_model';

@Pipe({
  name: 'logFormate'
})
export class LogFormatePipe implements PipeTransform {

  public logModal=LogModel;
  transform(value: string, ...args: unknown[]): unknown {
    var result;
    if(value.includes("[machine-abnormal-parcel-order-complate]")){
      var temp1 = value.split("[machine-abnormal-parcel-order-complate]")[1]
      var temp2 = temp1.split(" ");
      // console.log(temp2)
      result=this.logModal['[machine-abnormal-parcel-order-complate]']+' '+temp2[1]+' '+temp2[3]+' '+temp2[4];
    }else{
      result = this.logModal[value];
    }
    return result;
  }

}
