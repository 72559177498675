import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(data: any[], filterType: any, name: any, level: any): any {
    if (name=='') {
        return data;
    }
    var name_lowercase = name.toLowerCase()
    if(filterType == 'dashboard'){
      return data.filter(item => {
        item.bname = item['bname'].toLowerCase();
        item.baddr = item['baddr'].toLowerCase();
        return item.bname.includes(name_lowercase) || item.baddr.includes(name_lowercase)
      })
    }else if(filterType == 'list_view'){
      // console.log("data: ", data);
      // console.log("filterType: ", filterType);
      // console.log("Level: ", level);
      return data.filter(item => {
        if (level == '1'){
            item.bname = item['bname'].toLowerCase();
            item.baddr = item['baddr'].toLowerCase();
            return item.bname.includes(name_lowercase) || item.baddr.includes(name_lowercase)
        }
        item.bname = item['name'].toLowerCase();
        item.baddr = item['address'].toLowerCase();
        return item.bname.includes(name_lowercase) || item.baddr.includes(name_lowercase)
      })
    } else if(filterType == 'member_table_view'){
      return data.filter(item => item.name.toLowerCase().includes(name_lowercase) )
    }
    return data.filter(item => {
      item.bname = item['bname'].toLowerCase();
      return item.bname.includes(name_lowercase)
      
    });

  }


}
