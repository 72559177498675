import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adTimeFormat'
})
export class ADTimeFormat implements PipeTransform {


  transform(time: any) : any{
    
    
    return time.split(' ')[0]
    

  }

}