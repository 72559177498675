import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "communitySearch",
})
export class CommunitySearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, rev: any): any {
    
    var result = items;
    // filter by search text
    if (items.length > 0) {
      result = items.filter(
        (ele) => ele.title.toLowerCase().includes(searchText.toLowerCase()) || ele.body.toLowerCase().includes(searchText.toLowerCase())
      );
      // return result;
    }


    // sort rerverse
     // fitler sort
     if(rev ==0) {
      result = result.sort((a:any,b:any) => {
        if(a.priority != 1 && b.priority!=1){
          let date1:any = new Date(a.create_time);
          let date2:any = new Date(b.create_time)
          return date2 - date1
        }
      })
    }
    if(rev ==1) {
      result = result.sort((a:any,b:any) => {
        if(a.priority != 1 && b.priority!=1){
          let date1:any = new Date(a.create_time);
          let date2:any = new Date(b.create_time)
          return date1 - date2
        }
      })
    }
    if(rev==2 || rev==3){
      
      result = result.sort((a,b)=>{
        if(a.priority != 1 && b.priority!=1){
          if(rev ==2){
            return b.views - a.views
          } else {
            return a.views - b.views
          }
        }
          
      })
      
    }
    // console.log(items)
    

    
    return result;
  }
}
