import { Component, Input } from '@angular/core';
import { CoreMenuItem } from '@core/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import moment from 'moment';
import { CommunityAPIService } from 'app/service/community-api.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  haveNewReleaseNote: boolean = false  

  constructor(
    private http : HttpClient,
    private communityAPIService: CommunityAPIService
  ){}

  ngOnChanges(): void {
    if(this.item.id == 'release-notes'){
      this.getRecentRelease()
    }
  }

  getRecentRelease(){
    // reset noteList 
    let requestOptions_post : any = {
      headers: new HttpHeaders().set('Content-Type','application/json'),
      responseType: 'text'
    }
    this.http.post(environment.releaseNoteUrl + '/list', {'limit': 3}, requestOptions_post).subscribe(
      (ret: any) => {
        ret = JSON.parse(ret)
        ret.result.forEach(element => {
          element.create_time  = moment.utc(element.create_time).local().format('lll');
          element.category = element.category.split(',')
        });

        this.communityAPIService.recentNote = ret.result
        // console.log(ret, typeof ret)
        // console.log(moment().unix() - moment.utc(ret.result[0].create_time).unix())
        if(ret.result.length > 0) this.haveNewReleaseNote = moment().unix() - moment.utc(ret.result[0].create_time).unix() >= 604800 ? false : true
        // console.log(this.haveNewReleaseNote)        
      }
    )
  }
}
