import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adGroupPipe',
  pure: false
})
export class AdGroupPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(groupList: any[], type:string, isStar:boolean) : any{
    //   console.log(type + isStar)
    if(type == '0'){
        if(!isStar){
            return groupList;
        }else{
            var result = groupList.filter((item)=>
                item.maker =='1'
            );
            return result;
        }

    }else if(type=='1'){
        var temp_result = groupList.filter((item)=>
            item.hasAd == true
        )
        // console.log(temp_result);
        if(!isStar){
            return temp_result;
        }else{
            var result = groupList.filter((item)=>
              item.maker=='1'
            )
            return result;
        }
        
    }else if(type=='2'){
        var temp_result = groupList.filter((item)=>
            (item.hasAd==false &&
            item.sts==1)
        )
        if(!isStar){
            return temp_result;
        }else{
            var result = temp_result.filter((item)=>
                item.maker=='1'
              )
            return result;
        }
    }else{
        var temp_result = groupList.filter((item)=>
            (item.hasAd==false &&
            item.sts==0)
        )
        if(!isStar){
            return temp_result;
        }else{
            var result = temp_result.filter((item)=>
                item.maker=='1'
              )
            return result;
            }
        }
    }
}