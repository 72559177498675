import { CoreMenu } from '@core/types'
// Role: 
// System admin --> 10
// Building admin --> 1
// Advertisement admin --> 6
// Parcel admin --> 5
// Business partner indicator --> -2


export const miniBoxMenu: CoreMenu[] = [
    { 
        id: 'miniBox-list',
        type: 'section',
        title: 'MiniBox-Menu',
        icon:'',
        // children:[
        //   {
        //     id: 'bikelocker_location',
        //     type: 'collapsible',
        //     title: 'Location',
        //     translate: '',
        //     icon: 'codesandbox',
        //     role: ['1', '10'],
        //     children:[
        //       {
        //         id: 'bikelocker_list',
        //         title: 'List',
        //         icon: 'circle',
        //         role: ['1', '10'],
        //         url: 'bike/list',
        //         type: 'item',
        //         exactMatch: true
        //       },
        //       {
        //         id: 'bikelocker_session',
        //         title: 'Session',
        //         icon: 'circle',
        //         type: 'item',
        //         role: ['10', '1'],
        //         url: 'bike/session',
        //         exactMatch: true
        //       },
        //       {
        //         id: 'bikelocker_collection',
        //         title: 'Collection',
        //         icon: 'circle',
        //         type: 'item',
        //         role: ['10', '1'],
        //         url: 'bike/collection',
        //         exactMatch: true
        //       },
        //     ]
        //   },
        //   {
        //     id: 'bikelocker_support',
        //     title: 'Support',
        //     translate: '',
        //     type: 'item',
        //     icon: 'headphones',
        //     url: 'customer-service',
        //     exactMatch: false,
        //     role: ['5', '10']
        //   }
        // ]
    }

]