import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'oddeven'
})
export class OddEvenPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any[], type: string): any[] {
    // console.log(items);
    // type = 'even'
    var results = []
    if(items.length > 0){

        if(type == 'odd'){
            results = items.filter((item, index) => {
                if(index % 2 ==0){
                    return item
                }
            });
            // console.log(results)
        }
        if(type == 'even'){
            results = items.filter((item, index)=>{
                if(index % 2 !== 0){
                    return item
                }
            });
            // console.log(results)
            // return results
        } 
        
    }
    return results;
  }
}