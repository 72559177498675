import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adAgentFilter'
})
export class ADAgentFilter implements PipeTransform {


  transform(data: any[],  agent: any, par: any) : any{

    if(agent =='') return data;

    var result = [];
    if(par == 'gname'){
      return data.filter(item => item.gname.toUpperCase().includes(agent.toUpperCase()));
    }
    if(data.length > 0){
        result = data.filter(item => item.name.toUpperCase().includes(agent.toUpperCase()));
    }
    return result;

  }

}