import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adTypeNumPipe',
  pure: false
})
export class AdTypeNumPipe implements PipeTransform {


  transform(adList: any[], type:string, pictureSize:string) : any{
    //   console.log(type + pictureSize)
    var result = [];
    if (type){
        result = adList.filter((item)=> item.type == type);
    }
    if (pictureSize){
        result = result.filter((item)=> {
            let name = item.name.split('|')[1];
            return name == pictureSize
        });
    }
    return result;
  }
    
}