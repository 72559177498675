import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  currentPartnersSubject: BehaviorSubject<[]>;
  currentPartners: Observable<[]>
  constructor() {
    this.currentPartnersSubject = new BehaviorSubject<[]>(JSON.parse(localStorage.getItem('currentPartner')));
    this.currentPartners = this.currentPartnersSubject.asObservable();
  }

  setPartner(partners){
    this.currentPartnersSubject.next(partners);
    localStorage.setItem('currentPartner', JSON.stringify(partners));
  }
}
