import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { Role, User } from 'app/auth/models';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { ResourceService } from 'app/service/resource.service';
import { DataManageService } from 'app/service/data-manage.service';
import { environment } from 'environments/environment';
import { AvatarService } from 'app/service/avatar.service';
import { TimestampService } from 'app/service/timestamp.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { menu } from 'app/menu/menu';
import { bikeMenu } from 'app/menu/bike-menu';

@Component({
  selector: 'app-navbar1',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {

  public offlineLockers: object[] = []
  public isClosed = false  // track if the notification is closed 

  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  public username: any;
  public userLevel: any;
  public timeStamp: any;
  public currentMenu: any = 'main';

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  public currentAvatarUrl: Observable<string>;
  private currentAvatarSubject: BehaviorSubject<string>;
  private current_menu: BehaviorSubject<string>;
  avatarUrl_sub = "";
  avatarUrl = "";
  prefix = "";
  urls: any;
  isbPartner: boolean = false;
  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private resourceService: ResourceService,
    private dataManager: DataManageService,
    private _avatarService: AvatarService,
    private timestampService:TimestampService,
    private coreMenuService: CoreMenuService
  ) {
    this.current_menu = new BehaviorSubject<string>(localStorage.getItem('currentMenu'));
    if(!this.current_menu.value){
      this.current_menu.next('main');
      localStorage.setItem('currentMenu', 'main');
    }else{
      this.currentMenu = this.current_menu.value;
    }
    this.currentAvatarSubject = new BehaviorSubject<string>(localStorage.getItem('avatarUrl'));
    this.currentAvatarUrl = this.currentAvatarSubject.asObservable();
    

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(['/pages/login']);
    localStorage.setItem('avatarUrl', 'assets/images/avatars/0.png')
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentAvatarUrl.subscribe(x => this.avatarUrl_sub = x);
    this._authenticationService.currentUser.subscribe(x => {
      this.currentUser = x
    });
    // get the currentUser details from localStorage
    // this.resourceService.urls.subscribe(
    //   data=> {
    //     this.urls = data;
    //   }
    // );
    // if(!this.avatarUrl){
    //   this.getUserInfo();
    // }
    this._avatarService.avatarUrlChanged.subscribe(data => {
      // this.avatarUrl_sub = data;
      if (Object.keys(data).length > 0) {
        // console.log(data)
        this.avatarUrl_sub = data;
        this.setLinkPicture(this.avatarUrl_sub)
      }
    })

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      // this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    // if (this.coreConfig.layout.type == 'horizontal') {
    //   // On every media(screen) change
    //   this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
    //     const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
    //     if (isFixedTop) {
    //       this.isFixed = false;
    //     } else {
    //       this.isFixed = true;
    //     }
    //   });
    // }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });
    if (this.currentUser) {
      if(this.currentUser.bPartner){
        this.isbPartner = true;
      }
      this.getUserInfo();
      this.username = localStorage.getItem('username');
      this.userLevel = Object.keys(Role).find(key => Role[key] === this._authenticationService.currentUserValue.level);
    }

    if(this.currentUser && this.currentUser?.level != Role.SysAdmin){
      // console.log('enter nav')
      this.getOfflineLockers()
    }
    

  }

  closeNotification(){
    // console.log('close ')
    this.isClosed = true
  }

  getOfflineLockers(){
    var url_parameters = { 't': this._authenticationService.currentUserValue.token, m:"0", n:"1000" };
    // var url_parameters = { 't': this._authenticationService.currentUserValue.token, 'mg':this._authenticationService.currentUserValue.ret };
    var encoded_url = this.dataManager.encryptMsg(url_parameters)
    var temp_input = { act: 8012, m: 1, par: encoded_url }
    this.dataManager.http_request(temp_input).subscribe((response: any) => {
      response = JSON.parse(this.dataManager.decryptMsg(response));
      console.log(response)
      this.offlineLockers = response.filter(item => item.status !== '101' && item.run_sts == "1")
      console.log(this.offlineLockers)
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getUserInfo() {
    var pram = { 't': this._authenticationService.currentUserValue.token, bid: this._authenticationService.currentUserValue.ret };
    var encoded_url = this.dataManager.encryptMsg(pram);
    var temp_input = { act: 50046, m: 1, par: encoded_url };
    this.dataManager.http_request(temp_input).subscribe({
      next: ret => {
        var decrypted = this.dataManager.decryptMsg(ret);
        var user = JSON.parse(decrypted);
        if (user.avatar && user.avatar != '-1') {
          this.prefix = environment.rc_url;
          //this.prefix='https://devrc.bluemythglobal.com/blueimage/';
          this.avatarUrl = this.prefix + user.avatar;
        } else {
          this.avatarUrl = 'assets/images/avatars/0.png';
        }

        localStorage.setItem('avatarUrl', this.avatarUrl);
      }
    });
  }

  updateAvatarUrl(url) {
    this.avatarUrl = this.prefix + url;
    localStorage.setItem('avatarUrl', this.avatarUrl);
  }

  getTimeStamp() {
    return this.timestampService.getTime();
  }

  public setLinkPicture(url: string) {
    this.avatarUrl_sub = url;
    this.timeStamp = (new Date()).getTime();
  }

  public getLinkPicture() {
    if (this.timeStamp) {
      return this.avatarUrl_sub + '?' + this.timeStamp;
    }
    return this.avatarUrl_sub;
  }

  toggleMenu(){
    // console.log(this.coreMenuService.getCurrentMenu());
    this.currentMenu == 'bike' ? this.toggleMain() : this.toggleBike();
      
  }

  toggleMain(){
    this._coreConfigService.setConfig({app:{appName:'BlueManager'}}, {emitEvent: true});
    this.coreMenuService.setCurrentMenu('main');
    this.currentMenu = 'main';
    this._router.navigate(['/']);
  }

  toggleBike(){
    this._coreConfigService.setConfig({app:{appName:'BlueBox for Bikes'}}, {emitEvent: true});
    this.coreMenuService.setCurrentMenu('bike');
    this.currentMenu = 'bike';
    this._router.navigate(['/bike']);
  }

  toggleOtherMenu(app_name, menu_name, url){
    this._coreConfigService.setConfig({app:{appName:'BlueManager'}}, {emitEvent: true});
    this.coreMenuService.setCurrentMenu('main');
    this.currentMenu = 'main';
    this._router.navigate(['/']);
  }


}
