import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "communityLocation",
})
export class CommunityLocationPipe implements PipeTransform {
  transform(items: any[],locations: any): any {
    
    // filter by location
    // console.log(locations)
    if (locations.length > 0) {
      var result = items.filter((item) => {
        for (let t of item.tag) {
          if (locations.includes(t)) {
            return true;
          }
        }
        return false;
      });
      // console.log(result)
      return result
    }
    return items;
  }
}