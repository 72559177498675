import { Pipe, PipeTransform } from '@angular/core';
import { LogModel } from 'app/data/log_model';

@Pipe({
  name: 'redirectFilter'
})
export class RedirectFilterPipe implements PipeTransform {

  public logModal=LogModel;
  transform(redirect_list: any, search_value: any): any[] {
    if(!search_value){
      return redirect_list;
    }else{
        return redirect_list.filter(x => x.note.includes(search_value));
    }
  }

}
