import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DataManageService } from './data-manage.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  public avatarUrlChanged: BehaviorSubject<any>;

  public avatarUrl: any;
  constructor(private _authenticationService: AuthenticationService,
    private dataManager: DataManageService) { 
      this.avatarUrlChanged = new BehaviorSubject({});
      // this.getUserInfo();
    }
  getUserInfo(): Promise<any[]>{
    var pram = {'t':this._authenticationService.currentUserValue.token, bid:this._authenticationService.currentUserValue.ret};
    var encoded_url=this.dataManager.encryptMsg(pram);
    var temp_input = {act:50046, m:1, par:encoded_url};
    return new Promise((resolve, reject) => {
      this.dataManager.http_request(temp_input).subscribe((response: any)=>{
          var decrypted = this.dataManager.decryptMsg(response);
          var user = JSON.parse(decrypted);
          if(user.avatar && user.avatar!='-1'){
            var prefix=environment.rc_url;
            //this.prefix='https://devrc.bluemythglobal.com/blueimage/';
            this.avatarUrl = prefix + user.avatar + '?' + (new Date()).getTime();
          }else{
            this.avatarUrl = 'assets/images/avatars/0.png';
          }
          
          // console.log(this.avatarUrl)
          localStorage.setItem('avatarUrl', this.avatarUrl);
          this.avatarUrlChanged.next(localStorage.getItem('avatarUrl'));
          resolve(this.avatarUrl);
        
      }, reject);
    })
    
  }
}
