<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

  <!-- <div class="navbar-container d-flex content"> -->
  <div class="navbar-container content">
  <div class="d-flex">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->

    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>  -->

    <li class="nav-item d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>

    <!-- <li class="nav-item d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleMenu()">
        <span data-feather="bold" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->


    <app-navbar-notification *ngIf="!isbPartner"></app-navbar-notification>
    <!--/ Language selection -->

    <app-navbar-search></app-navbar-search>
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{username}}</span><span class="user-status">{{userLevel}}</span>
        </div>
        <span class="avatar"
          ><img
            class="round"
            [src]="getLinkPicture()"
            alt="avatar"
            height="40"
            width="40" /></span>
            <!--             src={{avatarUrl_sub}}?{{getTimeStamp()}}
 -->
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a>
        <div class="dropdown-divider"></div>
        <a class="danger" ngbDropdownItem (click)="logout()"
          ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
  </div>
  <ngb-alert [type]="'danger'" class="mt-1" *ngIf="offlineLockers.length > 0" [dismissible]="true" [ngClass]="{'zeroHeight': isClosed}" (closed)="closeNotification()" >
    <div class="alert-body">
      <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
      <span> Offline locker : 
        <strong *ngFor="let locker of offlineLockers; let last = last;">{{ locker.name }} 
        <span *ngIf="!last">;</span>
        </strong> 
      </span>
    </div>
  </ngb-alert>

</div>



