import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(rawNum: string) : any{
    // rawNum = "+1"+ rawNum;

    const countryCodeStr = rawNum.slice(0,3);
    const areaCodeStr = rawNum.slice(3,6);
    const midSectionStr = rawNum.slice(6,10);
    // const lastSectionStr = rawNum.slice(8);

    // return `${countryCodeStr} (${areaCodeStr}) ${midSectionStr} - ${lastSectionStr}`;
    return `${countryCodeStr} ${areaCodeStr} ${midSectionStr} `;
  }

}