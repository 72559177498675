import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pnotes'
})
export class PnotesPipe implements PipeTransform {

  transform(value: string, return_type:number): string {
    var temp = value.split('#');
    if(return_type==0){
      if(temp[0]){
        var result = temp[0].replace(/--/g, ' ').replace(/@@/g, '\'').replace(/%%/g, '\"').replace(/::/g, '\\');
        result = result.replace(/!!/g,'\"').replace(/]]/g,'\*').replace(/==/g,'\%');  
        return result.replace(/&nbsp;/g,' ');
      }else{
        return 'N/A';
      }
      
    }else{
      if(temp.length==1){
        return 'N/A';
      }else{
        var result = temp[1].replace(/--/g, ' ').replace(/@@/g, '\'').replace(/%%/g, '\"').replace(/::/g, '\\');
        result = result.replace(/!!/g,'\"').replace(/]]/g,'\*').replace(/==/g,'\%');
        return result.replace(/&nbsp;/g,' ');
      }
    }
  }

}
