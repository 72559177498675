import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "communityCategory",
})
export class CommunityCategoryPipe implements PipeTransform {
  transform(items: any[], category: any, status: any): any {
    
    if(status){
      items = items.filter((item)=> item.status == status);
    }

    if (category == 9) return items;
    // get reported posts
    if (category == 10) {
      var reported = items.filter(item => item.vote.length>0);
      return reported;
    }
    // filter by category
    if(category !==''){
        var result = items.filter((item)=> item.category == category);
        return result;
    }  
    
    return items;
  }
}