import { Injectable } from '@angular/core';
import { Buildings } from 'app/data/buildings';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {
  currentBuildingSubject:BehaviorSubject<Buildings[]>;
  buildings:Observable<Buildings[]>;

  allBuildingSubject:BehaviorSubject<any[]>;
  allBuildings: Observable<any[]>;

  allBikeLockerSubject:BehaviorSubject<any[]>;
  allBikeLockers: Observable<any[]>;



  constructor() { 
    this.currentBuildingSubject = new BehaviorSubject<Buildings[]>(JSON.parse(localStorage.getItem('currentBuilding')));
    this.buildings = this.currentBuildingSubject.asObservable();
    this.allBuildingSubject = new BehaviorSubject<any[]>(JSON.parse(localStorage.getItem('allBuildingInfo')));
    this.allBuildings = this.allBuildingSubject.asObservable();
    this.allBikeLockerSubject = new BehaviorSubject<any[]>(JSON.parse(localStorage.getItem('allBikeLockerInfo')));
    this.allBikeLockers = this.allBikeLockerSubject.asObservable();
  }

  public get currentBuildingValue(): Buildings[]{
    return this.currentBuildingSubject.value;
  }

  public get currentBikeLockerValue(): any[]{
    return this.allBikeLockerSubject.value;
  }
}
